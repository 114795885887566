import React, { useRef,useEffect } from 'react';
import './App.css';
import ChatInterface from './components/ChatInterface';
import AeremSolarGenieMascot from './images/solar_genie_mascot.jpg'



function App() {
  const logoContainerRef = useRef<HTMLDivElement>(null);
  console.log("App loaded")

  useEffect(() => {
    document.title = "Solar Genie"; // Set the tab title
  }, []);

  return (
    <div className=" flex flex-col sm:flex-row h-screen sm:w-screen w-full items-center sm:items-start">
      <div className=' 'ref={logoContainerRef}>
          {/* <h1 className="text-4xl font-semibold text-orange-400 mt-3 ml-8">Solar Genie</h1> */}
          <img className='sm:h-120 sm:w-60  h-60 w-40  rounded-lg ' src={AeremSolarGenieMascot}/>
      </div>
      <div className=' sm:w-3/5 w-full px-6 sm:px-0 h-screen'>
        <ChatInterface logoContainerRef={logoContainerRef}/>
      </div>
    </div>
  );
}

export default App;

