import React, { useState, useRef, useEffect } from 'react';
import { Paperclip, Send,X } from 'lucide-react';

interface ChatInputProps {
  onSendMessage: (message: string) => void;
  disabled?: boolean;
  isChatStarted?: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage, disabled, isChatStarted }) => {
  const [input, setInput] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [error, setError] = useState<string | null>(null);

  console.log("disabled", disabled  );

  
  // Add suggested questions
  const suggestedQuestions = [
    "I want to install solar",
    "What is the process to get started?",
    "How much can I save with solar?",
    "What plant capacity do I need?",
    "Do you have customer testimonials?",
    // "What is the cost of installing a solar plant"
  ];

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
    }
  };

  const uploadImage = async (file: File) => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch(`${process.env.REACT_APP_SOLAR_GENIE_BACKEND_URL}/uploadfile`, {
        method: 'POST',
        headers: {
          'accept': 'application/json, text/plain, */*',
          'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error('Upload failed');
      }

      const data = await response.json();
      return data.url;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    } finally {
      setIsUploading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (input.length > 500) {
      setError('Message cannot exceed 500 characters');
      return;
    }
    if ((input.trim() || selectedFile) && !disabled && !isUploading) {
      try {
        let finalMessage = input.trim();
        
        if (selectedFile) {
          const imageUrl = await uploadImage(selectedFile);
          finalMessage = finalMessage ? `${finalMessage} ${imageUrl}` : imageUrl;
          setSelectedFile(null);
        }

        onSendMessage(finalMessage);
        setInput('');
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        if (textareaRef.current) {
          textareaRef.current.style.height = 'auto';
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    // console.log(file?.size);
    if (file) {
      if (file.size > 10 * 1024 * 1024) { // 10MB in bytes
        alert('File size must be less than 10 MB');
        e.target.value = '';
        return;
      }
      setSelectedFile(file);
    }
  };

  const handleSuggestedQuestionClick = (question: string) => {
    setInput(question);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setInput(newValue);
    setError(newValue.length > 500 ? 'Message cannot exceed 500 characters' : null);
    adjustTextareaHeight();
  };

  // useEffect(() => {
  //   const welcomeUserMessage = 'Hi'
  //   onSendMessage(welcomeUserMessage);
  // }, []);

  return (
    <div className="w-full">
      <div className="mx-auto max-w-3xl sm:px-6 pb-2">
        {/* Suggested Questions */}
        <div className="mb-4 flex flex-wrap sm:gap-1 gap-1.5 justify-center">
          {!isChatStarted && suggestedQuestions.map((question, index) => (
            <button
              key={index}
              onClick={() => handleSuggestedQuestionClick(question)}
              disabled={disabled}
              className={`sm:px-3 sm:py-1.5 px-2.5 py-1 bg-gray-800 text-gray-300 rounded-full text-[0.6rem] sm:text-sm transition-colors ${
                disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-700'
              }`}
            >
              {question}
            </button>
          ))}
        </div>

        {/* {selectedFile && (
          <div className="mt-2 text-blue-500 mb-2 text-sm flex items-center gap-2">
            <Paperclip className="w-4 h-4" />
            <span>{selectedFile.name}</span>
          </div>
        )} */}

        {selectedFile && (
          <div className="mt-2 text-blue-500 mb-2 text-sm flex items-center gap-2">
            <Paperclip className="w-4 h-4" />
            <span>{selectedFile.name}</span>
            <button
              type="button"
              onClick={() => {
                setSelectedFile(null);
                if (fileInputRef.current) {
                  fileInputRef.current.value = '';
                }
              }}
              className="p-0.5 hover:bg-gray-700 rounded-full transition-colors"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="relative flex items-end bg-gray-700 rounded-xl shadow-xl">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            accept="image/*,application/pdf"
            disabled={disabled || isUploading}
            className="hidden"
          />
          
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            disabled={disabled || isUploading}
            className="absolute bottom-3 left-3 p-1 text-gray-400 hover:text-gray-300 transition-colors disabled:text-gray-600 disabled:cursor-not-allowed"
          >
            <Paperclip className="w-5 h-5" />
          </button>

          <textarea
            ref={textareaRef}
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            disabled={disabled || isUploading}
            placeholder={
              isUploading ? "Uploading..." :
              disabled ? "Waiting for response..." : 
              "Message Solar Genie"
            }
            rows={1}
            className={`w-full bg-transparent text-white placeholder-gray-400 pl-12 pr-12 py-3 resize-none overflow-hidden focus:outline-none focus:ring-0 border-0 ${
              error ? 'border-red-500' : ''
            } ${disabled ? 'cursor-not-allowed' : ''}`}
            style={{ maxHeight: '200px' }}
          />

          {error && (
            <div className="absolute -top-6 left-0 text-red-500 text-sm">
              {error}
            </div>
          )}

          <button
            type="submit"
            disabled={disabled || isUploading || (!input.trim() && !selectedFile)}
            className={`absolute bottom-3 right-3 p-1 transition-colors ${
              (input.trim() || selectedFile) && !disabled && !isUploading
                ? 'text-white hover:text-gray-300'
                : 'text-gray-600'
            } ${disabled ? 'cursor-not-allowed' : ''}`}
          >
            <Send className="w-5 h-5" />
          </button>
        </form>

        <div className="text-center mt-2">
          <p className="text-gray-500 text-xs">
            Solar Genie can make mistakes. Check important info.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChatInput;